.hidden {
    display: none;
}

#myProgress {
    width: 100%;
    background-color: bisque;
}

#myBar {
    width: 1%;
    height: 5px;
    background-color: green;
}